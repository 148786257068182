
import axios from 'axios';
export default {
    
    async createShippingPackageFiles(params)  {
        return await axios.post(`shipping_package_files/create` , params)
    },
    async createShippingPackageFilesList(params)  {
        return await axios.post(`shipping_package_files/create/list` , params)
    },
    async updateShippingPackageFilesColumn(column , value , data)  {
        return await axios.put(`shipping_package_files/update_list?${column}=${value}` , data)
    },
    async deleteShippingPackageFilesList(list)  {
        return await axios.delete(`shipping_package_files/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportShippingPackageFiles(column , value)  {
        return await axios.get(`shipping_package_files/report?${column}=${value}`)
    },
    async getAllShippingPackageFiles()  {
        return await axios.get(`shipping_package_files/all`)
    },
    async getOneShippingPackageFiles(shipping_package_file_id)  {
        return await axios.get(`shipping_package_files/all/${shipping_package_file_id}`)
    },
    async getShippingPackageFilesByColumn(column , value)  {
        return await axios.get(`shipping_package_files/filter?column=${column}&value=${value}`)
    },
    async deleteShippingPackageFiles(shipping_package_file_id)  {
        return await axios.delete(`shipping_package_files/delete/${shipping_package_file_id}`)
    },
    async updateShippingPackageFiles(shipping_package_file_id , params)  {
        return await axios.put(`shipping_package_files/update/${shipping_package_file_id}` , params)
    }
}