
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class=" py-2 mb-4">{{$store.getters.language.data.shipped_packages.title}}</h1>

			<form @submit.prevent="addShippedPackages" autocomplete="off">
				<v-layout row wrap>

				<v-flex xs12 lg4 xl4 md5 sm5>
						<v-text-field v-model="shipped_packages.shipped_package_zmc" type="text" :label="$store.getters.language.data.shipped_packages.shipped_package_zmc" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

				<v-flex xs12 lg4 xl4 md5 sm5>
						<v-text-field v-model="shipped_packages.shipped_package_shein" type="text" :label="$store.getters.language.data.shipped_packages.shipped_package_shein" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

				<v-flex xs12 lg4 xl4 md5 sm5>
						<v-text-field v-model="shipped_packages.shipped_package_kilo" type="text" :label="$store.getters.language.data.shipped_packages.shipped_package_kilo" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

				<v-flex xs12 lg4 xl4 md5 sm5>
						<v-text-field v-model="shipped_packages.shipped_package_counts" type="number" :label="$store.getters.language.data.shipped_packages.shipped_package_counts" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<!-- <v-flex xs12 lg4 xl4 md5 sm5>
                        <v-text-field v-model="shipped_packages.shipped_package_date" type="datetime-local" :label="$store.getters.language.data.shipped_packages.shipped_package_date" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
				<v-flex xs12 lg4 xl4 md5 sm5>
						<v-select class="mx-1" clearable :items="users" v-model="shipped_packages.user_id" dense filled outlined item-text="user_username" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.shipped_packages.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="shipped_package_id">
								<template v-slot:[`item.shipped_package_date`]="{ item }">
									<div>
										{{new Date(item.shipped_package_date).toISOString().split('T')[0]}} {{new Date(item.shipped_package_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
								<template v-slot:[`item.shipped_package_id`]="{ item }">
									<div>
										<v-btn icon :to="'/shipped_packages-list/'+item.shipped_package_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectShippedPackages(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
										<v-btn icon class="mx-1" @click="openDialog(item)">
											<v-icon> mdi-image-multiple </v-icon>
										</v-btn>
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteShippedPackagesList">{{$store.getters.language.data.shipped_packages.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.shipped_packages.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.shipped_packages.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteShippedPackages(selected_shipped_packages.shipped_package_id)">
						{{$store.getters.language.data.shipped_packages.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="image_dialog" persistent>
			<v-card>
				<v-card-title>
					Images
				</v-card-title>
				<v-card-text>
					<v-layout row wrap>
						<!-- {{selected_shipped_packages}} -->
						<v-flex xs12 lg2 xl2 md12 sm12 v-for="item,index in fileRows" :key="index">

							<div class="mx-3" @click="downloadImage(item.shipping_package_path)">

								<v-img max-width="500" v-bind:src="$imageURL + 'packages/image/' + item.shipping_package_path" />
							</div>
                            <!-- delete -->
                            <v-btn icon color="error" @click="deleteShippedPackagesFile(item.shipping_package_file_id)">
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>

						</v-flex>
					</v-layout>
					<v-layout row wrap>

						<form @submit.prevent="addFileAttachments" autocomplete="off">

							<v-flex xs12 lg3 xl3 md4 sm4>

								<v-btn :color="img_file == null ? 'secondary' : 'teal'" :outlined="img_file == null" :loading="loading_btn" large style="width:100%" @click="openFile">
									upload
									<v-icon>fas fa-check</v-icon>
								</v-btn>
							</v-flex>
							<v-flex xs12 lg5 xl2 md3 sm4>
								<input type="file" @change="processFile" ref="file" style=" display:none" />
							</v-flex>

							<v-flex xs12 lg2 xl2 md2 sm4>
								<v-btn color="primary" :loading="loading_btn" type="submit" v-if="img_file != null">{{$store.getters.language.data.file_attachments.add_btn}}</v-btn>
							</v-flex>

						</form>
					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="image_dialog = false">
						{{$store.getters.language.data.shop_items.cancel_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/shipped_packages.request.js'
	import filerequests from './../../requests/shipping_package_files.request.js'
	export default {
		data() {
			return {
				shipped_packages: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				fileRows: [],
				image_dialog: false,
				img_file: null,
				file_attachments: {},

				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_shipped_packages: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.shipped_packages.shipped_package_zmc,
						align: 'start',
						sortable: true,
						value: 'shipped_package_zmc',
					},
					{
						text: this.$store.getters.language.data.shipped_packages.shipped_package_shein,
						align: 'start',
						sortable: true,
						value: 'shipped_package_shein',
					},
					{
						text: this.$store.getters.language.data.shipped_packages.shipped_package_kilo,
						align: 'start',
						sortable: true,
						value: 'shipped_package_kilo',
					},
					{
						text: this.$store.getters.language.data.shipped_packages.shipped_package_counts,
						align: 'start',
						sortable: true,
						value: 'shipped_package_counts',
					},
					{
						text: this.$store.getters.language.data.shipped_packages.shipped_package_date,
						align: 'start',
						sortable: true,
						value: 'shipped_package_date',
					},
					{
						text: this.$store.getters.language.data.users.user_username,
						align: 'start',
						sortable: true,
						value: 'user_username',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'shipped_package_id',
					}
				],
			}
		},
		computed: {
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.auth.user
			}
		},
		mounted() {
			this.shipped_packages.user_id = this.user.user_id
			this.readShippedPackages();
		},
		methods: {
			openFile() {
				this.$refs.file.click();
			},
			processFile($event) {
				this.img_file = $event.target.files[0];
			},
			addFileAttachments() {
				this.loading_btn = true
				var formData = new FormData();
				formData.append('shipped_package_id', this.selected_shipped_packages.shipped_package_id);
				formData.append('shipped_package_path', this.img_file);

				filerequests.createShippingPackageFiles(formData).then(r => {
					if (r.status == 200) {
						this.readFileAttachments(this.file_attachments)
						this.file_attachments = {}
						this.file_attachments.shipped_package_id = this.selected_shipped_packages.shipped_package_id
						this.snackbar = {
							value: true,
							text: 'FileAttachments Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add FileAttachments',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			readFileAttachments(i) {
				this.loading = true
				var item_id = i.shipped_package_id
				filerequests.getShippingPackageFilesByColumn('shipped_package_id', item_id).then(r => {
					if (r.status == 200) {
						this.fileRows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read FileAttachments',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read FileAttachments',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			addShippedPackages() {
				this.loading_btn = true
				requests.createShippedPackages(this.shipped_packages).then(r => {
					if (r.status == 200) {
						this.shipped_packages = {
							user_id: this.user.user_id
						}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'ShippedPackages Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add ShippedPackages',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteShippedPackages(shipped_package_id) {
				requests.deleteShippedPackages(shipped_package_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.shipped_package_id != shipped_package_id
						})
						this.snackbar = {
							value: true,
							text: 'ShippedPackages Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},
			downloadImage(fileAttachmentPath) {
				// Assuming the image path is relative to your API or server
				const imageURL = `${this.$imageURL}packages/image/${fileAttachmentPath}`;
				const link = document.createElement('a');
				link.href = imageURL;
				link.target = "_blank";
				link.download = "image_download";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
			deleteShippedPackagesList() {
				let ids = this.selected_rows.map(m => m.shipped_package_id)
				requests.deleteShippedPackagesList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.shipped_package_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' ShippedPackages Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},
			openDialog(i) {
				this.selected_shipped_packages = i
				this.file_attachments.shipped_package_id = i.shipped_package_id
				this.readFileAttachments(i)
				this.image_dialog = true
			},
			readShippedPackages() {
				this.loading = true
				requests.getAllShippedPackages().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShippedPackages',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShippedPackages',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectShippedPackages(i) {
				this.selected_shipped_packages = i
				this.delete_dialog = true
			},
            deleteShippedPackagesFile(shipping_package_file_id) {
                filerequests.deleteShippingPackageFiles(shipping_package_file_id).then(r => {
                    if (r.status == 200) {
                        this.fileRows = this.fileRows.filter(f => {
                            return f.shipping_package_file_id != shipping_package_file_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'FileAttachments Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            }
		},
	}
</script>
                    